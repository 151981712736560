// core
import React, { useState,useEffect} from 'react';
import { useHistory, useLocation} from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { actions as authUserActions } from '../../store/reducers/authUser';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// components
import {
  TextInput,
  PasswordInput,
  RadioSelectInput,
  DateInput,
  SelectPhoneInput,
} from '../../components/form-group';
import { CrudDetailsRequestModel } from '../../api/models';
import jwt, { JwtPayload } from 'jsonwebtoken';
// helpers
import { validateFields, serializeFields, validators } from '../helpers';
import Button from '../../components/button';
import SelectInput from '../../components/form-group/SelectInput/SelectInput';

import countryCodes from '../../models/country-dialcodes.json';
import prepareData from './prepareData';
import { Dict } from '../../models/interfaces';
import { State } from '../../store/interfaces';
import ClickLink from '../../components/click-link';
import { useGTagConversionTracker } from '../../hooks/gtag';
import { GtagSendTo } from '../../utils/gtag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AxiosError } from 'axios';

const UAECountryName = 'United Arab Emirates';
const noVisaCountries = [
  'Saudi Arabia',
  'Kuwait',
  'United Arab Emirates',
  'Qatar',
  'Bahrain',
  'Oman',
];

const countryCodeList: Array<{ label: string; value: string }> = [];
const countryCodesList = countryCodes as { [key: string]: string };
Object.keys(countryCodes).forEach((codeKey) =>
  countryCodeList.push({
    label: countryCodesList[codeKey],
    value: countryCodesList[codeKey],
  })
);

const StepForm: React.SFC = () => {
  const location = useLocation();
  const [token, setToken] = useState <string | null>(null);
 

  useGTagConversionTracker({
    allow_custom_scripts: true,
    u1: location.pathname,
    send_to: GtagSendTo.Signup,
  });

  const dispatch = useDispatch();
  const onSubmit = (data: any) => dispatch(authUserActions.signup(data));

  const { isLoading } = useSelector((state: State) => state.authUser);
  
  const [options, setOptions] = useState([
    { label: 'Automatic', value: 'automatic' },
    { label: 'Manual', value: 'manual' },
  ]);
  const [genderOptions, setGenderOptions] = useState([
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ]);
  const history = useHistory();

  const [fields, setFields] = useState<Dict>({
    name: {
      value: '',
      name: 'Full Name',
      label: 'Full Name *',
      error: '',
      placeholder: 'Enter full name',
      validators: [
        validators.required,
        validators.regexPattern(/^[a-zA-Z ]+$/),
      ],
    },
    countryCode: {
      value: '971',
    },
    startingCode: {
      value: '50',
    },
    number: {
      value: '',
      name: 'Phone Number',
      label: 'Phone Number *',
      error: '',
      placeholder: 'Enter your phone number',
      validators: [validators.required],
    },
    email: {
      value: '',
      name: 'Email Address',
      label: 'Email Address *',
      error: '',
      placeholder: 'Enter your email',
      validators: [validators.required, validators.email],
    },
    password: {
      value: '',
      name: 'Password',
      label: 'Password *',
      error: '',
      placeholder: 'Your password should be 6 characters long',
      validators: [
        validators.required,
        validators.minLength(
          6,
          'Password should be at least 6 characters long'
        ),
      ],
    },
    confirmPassword: {
      value: '',
      name: 'Confirm Password',
      label: 'Confirm Password *',
      error: '',
      placeholder: 'Re-enter password to confirm',
      validators: [validators.required],
    },

    licenseTypeId: {
      value: '',
      name: 'Driving License Type',
      label: 'Driving License Type *',
      placeholder: 'Select License Type',
      error: '',
      validators: [validators.required],
    },
    licenseTypeCategory: {
      value: '',
    },
    gearType: {
      value: '',
      name: 'Gear Type',
      label: 'Gear Type *',
      error: '',
      validators: [validators.required],
    },
    gender: {
      value: 'male',
      name: 'Gender',
      label: 'Gender *',
      error: '',
      validators: [validators.required],
    },

    dob: {
      value: '',
      name: 'Date Of Birth',
      label: 'Date Of Birth *',
      error: '',
      placeholder: 'DD / MM / YYYY',
      validators: [validators.required],
    },
    language: {
      value: '',
      name: 'Language',
      label: 'Language *',
      error: '',
      placeholder: 'Select your language',
      validators: [validators.required],
    },
    hasResidencyVisa: {
      value: undefined,
      name: 'Residency Visa Status',
      label: 'Do you have a residency visa in UAE? *',
      error: '',
      validators: [
        validators.required,
        // validators.equals(
        //   true,
        //   'You need to have residency Visa in UAE to register!'
        // ),
      ],
    },
    nationality: {
      value: '',
      name: 'Nationality',
      label: 'Nationality *',
      error: '',
      placeholder: 'Select your nationality',
      validators: [validators.required],
    },
    whatsAppPhone: {
      value: '',
      name: 'whatsAppPhone',
      label: 'whatsAppPhone',
      error: '',
      placeholder: 'Enter your WhatsApp Number',
      dailCode: '971',
    },
    provideWhatsAppNumber: true,
    validWhatsAppNumber: true,
  });

 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get('token');
    if (value) {
      setToken(value);
    } else {
      history.push('/signup');
    }
  }, [location.search, history]);

  
  useEffect(() => {
    if (token) {
      try {
        const secret = 'RTABus';
        const decoded = jwt.verify(token, secret) as JwtPayload;

        if (decoded && typeof decoded.exp === 'number') {
          const currentTime = Date.now();
          const expirationTime = decoded.exp * 1000;

          if (currentTime < expirationTime) {
            history.push(`/signup?token=${token}`);
          } else {
            
            history.push('/signup');
          }
        } else {
         
          history.push('/page-not-found');
        }
      } catch (err) {
       
        alert('Invalid or expired token. Please try again.');
        history.push('/page-not-found');
      }
    }
  }, [token,history]);

  const getInput = (inputField: string, value: any) => {
    if (inputField === 'nationality' && value === UAECountryName) {
      const fieldName = 'hasResidencyVisa';
      setFields({
        ...fields,
        [fieldName]: {
          ...fields.hasResidencyVisa,
          value: false,
        },
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    } else {
      setFields({
        ...fields,
        [inputField]: {
          ...fields[inputField],
          value,
        },
      });
    }
  };

  const loadGearType = async (licenseTypeId: any) => {
    const request: CrudDetailsRequestModel = {
      resource: 'entity/v1/license-type',
      resourceId: licenseTypeId,
    };
    const gearTypeoptionsOptions: any = [];
    const { data } = await api.crud.get(request);
    if (data && data.gearTypes) {
      data.gearTypes.forEach((item: string) => {
        gearTypeoptionsOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setOptions(gearTypeoptionsOptions);
    }
    const genderTypeOptions: any = [];
    if (data && data.genders) {
      data.genders.forEach((item: string) => {
        genderTypeOptions.push({
          label: item.charAt(0).toUpperCase() + item.substr(1),
          value: item.toLowerCase(),
        });
      });
      setGenderOptions(genderTypeOptions);
    }
  };

  const validateCustomFields = (customeFields: Dict) => {
    let areCustomFieldsValid = true;
    if (
      !customeFields.number.value ||
      !customeFields.countryCode.value ||
      !customeFields.startingCode.value
    ) {
      return { areCustomFieldsValid: false, updatedCustomFields: {} };
    }

    const validatorMessage = validators.phone(
      'number',
      customeFields.countryCode.value +
        customeFields.startingCode.value +
        customeFields.number.value,
      customeFields.number.name || customeFields.number.label
    );
    customeFields.number.error = validatorMessage;

    if (validatorMessage.length) {
      areCustomFieldsValid = false;
    }

    return { areCustomFieldsValid, updatedCustomFields: customeFields };
  };

  const validatePasswordFields = (customeFields: Dict) => {
    const { password, confirmPassword } = customeFields;
    if (password.value === confirmPassword.value) {
      return undefined;
    }
    return 'Password Mismatch! Both passwords must be same!';
  };

  const validateHasResidencyVisaField = (customeFields: Dict) => {
    const { hasResidencyVisa, nationality } = customeFields;
    if (
      hasResidencyVisa.value === false &&
      !noVisaCountries.includes(nationality.value)
    ) {
      return 'You need to have residency Visa in UAE to register!';
    }
    return undefined;
  };

  const submitForm = async (readyFields: Dict) => {
    const data = prepareData(readyFields);
    try {
      await onSubmit(data);
      if(token) {
        history.replace(`/signup/otp?token=${token}`);
      }

      else {
        history.replace('/signup/otp');
      }
      
    } catch (err) {
      const error = err as AxiosError;
      if (error && error.response && error.response.data) {
        formatErrors(error?.response?.data?.errors||[]);
      }
      
    }
  };

  const formatErrors = (errors: any) => {
    const errorFields: Dict = {};
    if (Array.isArray(errors)) {
      for (const error of errors) {
        const field = error.context.key;
        const formField = field === 'phone' ? 'number' : field;
        const message = `Invalid ${fields[formField].name ||
          fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    } else {
      const errorkeys = Object.keys(errors);
      for (const field of errorkeys) {
        const formField = field === 'phone' ? 'number' : field;

        const message =
          errors[field].message ||
          errors[field].errorMessage ||
          `Invalid ${fields[formField].name || fields[formField].label}`;
        if (fields[formField]) {
          errorFields[formField] = {
            ...fields[formField],
            error: message,
          };
        } else {
          toastr.error(message, '');
        }
      }
    }

    setFields({
      ...fields,
      ...errorFields,
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    const validateOptions = {
      fieldsToValidate: fields,
    };

    const whatsAppPhone = fields.whatsAppPhone.value;
    const whatsAppInputText = whatsAppPhone.replace(fields.whatsAppPhone.dailCode?.dialCode,'');
    if(!whatsAppInputText){
      setFields({ ...fields,provideWhatsAppNumber: false });
    }else{
      setFields({ ...fields,provideWhatsAppNumber: true });
    }
    const { areFieldsValid, updatedFields } = validateFields(validateOptions);
    const validatedFields = { ...fields, ...updatedFields };
    if (!areFieldsValid) {
      setFields(validatedFields);
      return;
    }
    const { areCustomFieldsValid, updatedCustomFields } = validateCustomFields(
      validatedFields
    );
    // show error messages (if any)
    const updatedValidatedFields = {
      ...validatedFields,
      ...updatedCustomFields,
    };
    if (!areCustomFieldsValid) {
      setFields(updatedValidatedFields);
      return;
    }
    const passwordMatchErrorMessage = validatePasswordFields(
      updatedValidatedFields
    );
    if (passwordMatchErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        confirmPassword: {
          ...updatedValidatedFields.confirmPassword,
          error: passwordMatchErrorMessage,
        },
      });
      return;
    }
    const residencyVisaErrorMessage = validateHasResidencyVisaField(
      updatedValidatedFields
    );
    if (residencyVisaErrorMessage) {
      setFields({
        ...updatedValidatedFields,
        hasResidencyVisa: {
          ...updatedValidatedFields.hasResidencyVisa,
          error: residencyVisaErrorMessage,
        },
      });
      return;
    }

    if (whatsAppPhone && whatsAppInputText) {
      const phoneValidator = PhoneNumberUtil.getInstance();
      const valid = phoneValidator.isValidNumber(phoneValidator.parse(`+${whatsAppPhone}`));
      if(!valid){
        setFields({ ...fields,validWhatsAppNumber: false });
      }else{
        setFields({ ...fields,validWhatsAppNumber: true });
        const readyFields = serializeFields(fields);
        submitForm(readyFields);
      }
    }else{
      setFields({ ...fields,validWhatsAppNumber: false });
    }
  };

  const renderFieldGroupOne = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <SelectInput
            api="entity/v1/license-type"
            label={fields.licenseTypeId.label}
            placeholder={fields.licenseTypeId.placeholder}
            optionLabel="name"
            optionValue="_id"
            onChange={(value, selectedOption) => {
              setFields({
                ...fields,
                licenseTypeId: {
                  ...fields.licenseTypeId,
                  value,
                },
                licenseTypeCategory: {
                  ...fields.licenseTypeCategory,
                  value: selectedOption.name,
                },
                gearType: {
                  ...fields.gearType,
                  value: '',
                },
              });
              loadGearType(value);
            }}
            error={fields.licenseTypeId.error}
            isSearchable={false}
            default={fields.licenseTypeId.value}
          />
        </div>
        {/* {fields.licenseTypeId.value && ( */}
        <div className="col-md-6 col-12">
          <RadioSelectInput
            name="gearType"
            key="gearType"
            label={fields.gearType.label}
            options={options}
            onChange={(value) => getInput('gearType', value)}
            error={fields.gearType.error}
            default={fields.gearType.value}
          />
        </div>
        {/* )} */}
      </div>
    );
  };

  const renderFieldGroupTwo = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <TextInput
            label={fields.name.label}
            default={fields.name.value}
            onChange={(value) => {
              getInput('name', value);
            }}
            placeholder={fields.name.placeholder}
            error={fields.name.error}
            // width={{ select: 20, text: 80 }}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextInput
            label={fields.email.label}
            onChange={(value) => getInput('email', value)}
            placeholder={fields.email.placeholder}
            error={fields.email.error}
            default={fields.email.value}
          />
        </div>
      </div>
    );
  };

  const renderFieldGroupThree = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <RadioSelectInput
            name="gender"
            key="gender"
            label={fields.gender.label}
            options={genderOptions}
            onChange={(value) => getInput('gender', value)}
            error={fields.gender.error}
            default={fields.gender.value}
          />
        </div>
        <div className="col-md-6 col-12">
          <SelectPhoneInput
            type="number"
            label={fields.number.label}
            data={countryCodeList}
            defaultCode={fields.countryCode.value}
            defaultSelected={fields.startingCode.value}
            defaultEntered={fields.number.value}
            onChange={(value) => {
              setFields({
                ...fields,
                countryCode: {
                  ...fields.countryCode,
                  value: value.code,
                },
                startingCode: {
                  ...fields.startingCode,
                  value: value.selected,
                },
                number: {
                  ...fields.number,
                  value: value.entered,
                },
              });
            }}
            placeholder={fields.number.placeholder}
            error={fields.number.error}
            enableSelectSearch
            disableNumberScrolls
          />
        </div>
      </div>
    );
  };

  const renderFieldGroupFour = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <DateInput
            label={fields.dob.label}
            onChange={(value) => getInput('dob', value)}
            placeholder={fields.dob.placeholder}
            error={fields.dob.error}
            default={fields.dob.value}
            maxDate={new Date()}
          />
        </div>

        <div className="col-md-6 col-12">
          <SelectInput
            api="entity/v1/languages"
            label={fields.language.label}
            placeholder={fields.language.placeholder}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('language', value)}
            error={fields.language.error}
          />
        </div>
      </div>
    );
  };

  const renderFieldGroupFive = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <SelectInput
            api="entity/v1/countries"
            label={fields.nationality.label}
            placeholder={fields.nationality.placeholder}
            optionLabel="name"
            optionValue="name"
            onChange={(value) => getInput('nationality', value)}
            from={"nationality"}
            error={fields.nationality.error}
          />
        </div>
        <div className="col-md-6 col-12">
          <RadioSelectInput
            type="boolean"
            name="hasResidencyVisa"
            key="hasResidencyVisa"
            label={fields.hasResidencyVisa.label}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={(value) => getInput('hasResidencyVisa', value)}
            error={fields.hasResidencyVisa.error}
            default={fields.hasResidencyVisa.value}
          />
        </div>
      </div>
    );
  };
  const renderFieldGroupSix = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12">
          <PasswordInput
            label={fields.password.label}
            onChange={(value) => getInput('password', value)}
            placeholder={fields.password.placeholder}
            error={fields.password.error}
          />
        </div>
        <div className="col-md-6 col-12">
          <PasswordInput
            label={fields.confirmPassword.label}
            onChange={(value) => getInput('confirmPassword', value)}
            placeholder={fields.confirmPassword.placeholder}
            error={fields.confirmPassword.error}
          />
        </div>
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="row">
        <div className="col-md-6 col-12 mt-2">
          <Button type="submit" disabled={false} expand>
            Next
          </Button>
        </div>
      </div>
    );
  };

    const renderFieldGroupSeven = () => {
    return (
      <div className="row" >
        <div className="col-md-6 col-12">
            <label className="Input__label" >WhatsApp Number *</label>
            <PhoneInput
              autoFormat
              country="ae"
              preferredCountries={['ae']}
              enableSearch={false}
              placeholder="Phone Number"
              inputClass="Input__field Input__field--md"
              containerClass="textField"
              dropdownStyle={{
                borderColor: '#e5e6e7',
                margin: '0px',
              }}
              disableCountryCode={false}
              countryCodeEditable={false}
              inputStyle={{
                width: '100%',
                height: '56px',
              }}
              disableSearchIcon
              onChange={(value,country) =>
                setFields({
                  ...fields,
                  whatsAppPhone: {
                    value,
                    dailCode : country,
                  },
                })
              }
            />
            <span className="Input__error">
               {!fields.validWhatsAppNumber  ? 'Please provide valid whatsapp number' : ''}
               {!fields.provideWhatsAppNumber ? 'Please enter any whatsapp number' : ''}
            </span>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="Registration py-5 mt-lg-4 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-11 col-12">
              <div className="pb-md-5 pb-4 font-weight-normal text-center">
                <h3 className="">Register Now</h3>
                <p className="text-muted m-0">
                  Already have an account?{' '}
                  <ClickLink link="/auth/login" underline primary>
                    Login
                  </ClickLink>
                </p>
              </div>
              {renderFieldGroupOne()}
              {renderFieldGroupTwo()}
              {renderFieldGroupThree()}
              {renderFieldGroupFour()}
              {renderFieldGroupFive()}
              {renderFieldGroupSix()}
              {renderFieldGroupSeven()}
              {renderSubmitButton()}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepForm;
